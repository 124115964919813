a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a{ color: black; }


.loginForm{
    width: 55%;
    height: 55%;
    margin: auto;
    margin-top: 15px;
    padding: 12px;
    border: 2px rgb(68, 68, 68) solid;
    background-color: rgb(213, 213, 213);
    border-radius: 5px;
}

@media (max-width:900px) {
    .loginForm{
        width: 65%;
        height: 65%;
        margin: auto;
        margin-top: 15px;
        padding: 12px;
        border: 2px rgb(68, 68, 68) solid;
        background-color: rgb(213, 213, 213);
        border-radius: 5px;
    }
}

@media (max-width:700px) {
    .loginForm{
        width: 75%;
        height: 75%;
        margin: auto;
        margin-top: 15px;
        padding: 12px;
        border: 2px rgb(68, 68, 68) solid;
        background-color: rgb(213, 213, 213);
        border-radius: 5px;
    }
}

@media (max-width:500px) {
    .loginForm{
        width: 85%;
        height: 85%;
        margin: auto;
        margin-top: 15px;
        padding: 12px;
        border: 2px rgb(68, 68, 68) solid;
        background-color: rgb(213, 213, 213);
        border-radius: 5px;
    }
}