a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


.trackWindow{
    margin: auto;
    margin-top: 15px;
    padding: 12px;
    padding-bottom: 19px;
    max-width: 93%;
    max-height: 93%;
    justify-content: space-between;
    align-content: center;
    border: 4px rgb(85, 85, 85) solid;
    border-radius: 9px;
}

.propsList{
    margin: auto;
    padding: 5px;
}

.propsList span {
    margin: 15px;
    font-size: 35px;
    font-weight: bold;
}

.indivClient{
    display:table;
    justify-content: space-evenly;
    align-content: center;
    margin: auto;
    margin-top: 12px;
    padding: 10px;
    width: fit-content;
    min-width: 80%;
    max-height: 20%;
    border: 2px rgb(104, 104, 104) solid;
    border-radius: 9px;
}

.indivClientVisited{
    background-color: rgb(216, 215, 215);
    opacity: 68%;
    display:table;
    justify-content: space-evenly;
    align-content: center;
    margin: auto;
    margin-top: 12px;
    padding: 10px;
    width: fit-content;
    min-width: 80%;
    max-height: 20%;
    border: 2px rgb(104, 104, 104) solid;
    border-radius: 9px;
}

.indivClient span {
    font-size: 35px;
}

.indivClient:hover{
    transform: scale(1.2);
    transition: ease 0.32s;
}

.clientProp{
    display:inline-flex;
    margin: 7px;
    padding-right: 10px;
}

.clientDetail{
    margin: auto;
    margin-top: 15px;
    padding: 12px;
    padding-bottom: 19px;
    max-width: 93%;
    max-height: 93%;
    justify-content: space-between;
    align-content: center;
    border: 4px rgb(85, 85, 85) solid;
    border-radius: 9px;
}

.clientDetailPage{
    margin: auto;
    margin-top: 15px;
    padding: 12px;
    padding-bottom: 19px;
    font-size: xx-large;
    max-width: 95%;
    max-height: 93%;
    justify-content: space-between;
    align-content: center;
    border: 4px rgb(85, 85, 85) solid;
    border-radius: 9px;
}

.clientPropPage{
    display:inline-flex;
    justify-content: space-between;
    margin: 7px;
    font-size: x-large;
    font-style: italic;
    font-weight: normal;
}

@media (max-width:800px) {
    .propsList span {
        margin: 15px;
        font-size: 22px;
        font-weight: bold;
    }

    
    .indivClient span {
        font-size: 22px;
    }
}

@media (max-width:600px) {
    .propsList span {
        margin: 15px;
        font-size: 22px;
        font-weight: bold;
    }
    
    .indivClient span {
        font-size: 22px;
    }

    .indivClient{
        font-size: 22px;
        font-weight: 300;
    }

    .propsList {
        margin: 15px;
        font-size: 22px;
        font-weight: bold;
    }

    .deliveredButton{
        display: inline-flex;
        height:75px;
        margin-right: 65px;
        width: 90%;
    }
    
    .notDeliveredButton{
        display: inline-flex;
        height:75px;
        margin-left: 65px;
        width: 90%;
    }
}
